import React, {useState, useCallback} from 'react';
import {getMatch} from '../api';
import MatchForm from '../components/MatchForm';
import MatchResult from '../components/MatchResult';
export default function Match() {

  const [matchQuery, setMatchQuery] = useState({
    name:"awef",
    address:"",
    preferExperience:3,
    preferNearest:3,
    preferComputer:3,
  },[])
  const [loading, setLoading] = useState(false);
  const [matchResult, setMatchResult] = useState();
  const [matchErrorMessage, setMatchErrorMessage] = useState("");
  
  const onChange = useCallback((e)=>{
    e.preventDefault();
    setMatchQuery((matchQuery)=>{
      return {
        ...matchQuery,
        [e.target.name]: e.target.value
      };
    })
  })
  console.log("matchQuery:",matchQuery);
  const onSubmit = useCallback((e)=>{
    e.preventDefault();
    
    setLoading(true);
    setMatchErrorMessage("")
    setMatchResult(null);
    const matchResult = getMatch(matchQuery)
      .then(
        (res)=>{
          setLoading(false);
          setMatchResult(res.data);
        }
      ).catch(
        (error)=>{
          setLoading(false);
          if (error.response && error.response.status===400){
            setMatchErrorMessage(error.response.data?.cause)
          } else {
            alert('오류 발생, 콘솔 확인');
            console.log("error!!", error);
          }
        }
      );
    setMatchQuery((item)=>matchQuery);
  },[matchQuery])
  return (
    <div class='flex flex-col gap-[20px] ml-[20px]'>
      <div class='mt-[20px] text-4xl'>
        이력서 매칭
      </div>
      <MatchForm
        onSubmit={onSubmit}
        onChange={onChange}
        matchQuery={matchQuery}
        loading={loading}
        errorMessage={matchErrorMessage}
      />
      <MatchResult result={matchResult}/>
    </div>
  );
}
