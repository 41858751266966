import { useEffect, useState } from "react";
import { getAllUsers } from "../api";
import { Pagination, Table } from "antd";
import moment from "moment";
import Search from "antd/es/input/Search";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [username, setUsername] = useState(null);

  const columns = [
    {
      title: "이름",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "전화번호",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "이메일",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "성별",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "생년월일",
      dataIndex: "birthDate",
      key: "birthDate",
      render: (text) => moment(text).format("YYYY.MM.DD"),
    },
  ];

  useEffect(() => {
    getAllUsers(page, size, username)
      .then((res) => {
        setUsers(res.data.content);
        setTotalElements(res.data.totalElements);
        setSize(res.data.size);
      })
      .catch((e) => console.log(e));
  }, [page, username]);

  const onChangePage = (page, pageSize) => {
    setPage(page - 1);
  };

  const onSearch = (value, _e, info) => {
    if(value == "") {
      setUsername(null);
    } else {
      setUsername(value);
    }
  }

  return (
    <div className="mx-auto my-[20px]">
      <div className="flex flex-col justify-center gap-[20px]">
        <Search
          placeholder="이름으로 검색하기"
          onSearch={onSearch}
          style={{ width: 200 }}
        />
        <Table
          rootClassName="w-[70vw]"
          columns={columns}
          dataSource={users}
          pagination={false}
          size="large"
        />
        <Pagination
          rootClassName="flex justify-center mt-[20px]"
          current={page + 1}
          pageSize={size}
          total={totalElements}
          onChange={onChangePage}
        />
      </div>
    </div>
  );
}
