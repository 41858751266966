import { Layout, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { Link, Outlet } from "react-router-dom";

function App() {
  const onClick = (e) => {
    console.log(e);
  };
  return (
    <>
      <Layout className="h-[100vh]">
        <Sider>
          <Menu
            onClick={onClick}
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items}
          />
        </Sider>
        <Outlet />
      </Layout>
    </>
  );
}

const items = [
  {
    key: "1",
    label: <Link to="/">홈</Link>,
  },
  {
    key: "2",
    label: <Link to="/users">사용자 조회</Link>,
  },
  {
    key: "3",
    label: <Link to="/applications">이력서 조회</Link>,
  },
  {
    key: "4",
    label : <Link to="/match">이력서 매칭 조회</Link>
  }
];

export default App;
