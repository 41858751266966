import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Applications from "./pages/Applications";
import Login from "./pages/Login";
import Match from "./pages/Match";
import Redirect from "./pages/Redirect";
import Users from "./pages/Users";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/applications",
        element: <Applications />,
      },
      {
        path: "/match",
        element: <Match/>
      }
    ],
  },
  {
    path: '/oauth2',
    children: [
      {
        path: 'redirect',
        element: <Redirect />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />
  }
]);

export default router;
