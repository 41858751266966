import { Button, Pagination, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { getAllResumes, deleteResumeWithApplication } from "../api";

export default function Applications() {
  const [resumes, setResumes] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);

  const columns = [
    {
      title: "지원서 id",
      dataIndex: "resumeId",
      key: "resumeId",
    },
    {
      title: "지원자 이름",
      dataIndex: "user",
      key: "username",
      render: (user) => user?.name,
    },
    {
      title: "전화번호",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "이메일",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "지원 시간",
      dataIndex: "createdDateTime",
      key: "createdDateTime",
      render: (createdDateTime) =>
        moment(createdDateTime).format("YYYY.MM.DD HH시 mm분 ss초"),
    },
    {
      title: "이력서 보기",
      dataIndex: "resumeId",
      key: "application-link",
      render: (resumeId) => <Button type="link" onClick={() => onRowClick(resumeId)}>이력서 보러가기</Button>
    },
    {
      title: "이력서 삭제",
      dataIndex: "resumeId",
      key: "application-delete",
      render: (resumeId) => <Button type="link" onClick={()=>onDeleteClick(resumeId)}>이력서 삭제하기</Button>
    },
  ];
  
  useEffect(() => {
    getAllResumes(page, size)
      .then((res) => {
        setResumes(res.data.content);
        setTotalElements(res.data.totalElements);
        setSize(res.data.size);
        console.log("res.data:",res.data.content);
      })
      .catch((e) => console.log(e));
  }, [page]);

  const onChangePage = (page, pageSize) => {
    setPage(page - 1);
  };

  const onRowClick = (id) => {
    window.open(`https://higherre.com/resumes/${id}`, "_blank");
  };
  const onDeleteClick = (id)=> {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      deleteResumeWithApplication(id)
      .then((res)=>{
        window.location.reload();
      }).catch((e)=>{
        console.error(e);
        alert("삭제 실패, 콘솔 확인");
      });
    }
    
  }

  return (
    <div className="mx-auto my-[20px]">
      <div className="flex flex-col justify-center">
        <Table
          rootClassName="w-[70vw]"
          columns={columns}
          dataSource={resumes}
          pagination={false}
          size="large"
        />
        <Pagination
          rootClassName="flex justify-center mt-[20px]"
          current={page + 1}
          pageSize={size}
          total={totalElements}
          onChange={onChangePage}
        />
      </div>
    </div>
  );
}
