import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAdminUserInfo, getCurrentUserInfo } from '../api';
import { set } from '../reducers/auth/authReducer';

function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getAdminUserInfo().then((res) => {
      console.log(res)
      if(window.location.pathname !== "/login" && res.data === "") {
        window.location.href = "/login"
      } else {
        dispatch(set(res.data));
        setIsLoaded(true);
      }
    }).catch(e => {
      console.log(e);

      if(window.location.pathname !== "/login") {
        window.location.href = "/login"
      }
    });
  }, []);

  return <>{isLoaded ? children : null}</>;
}

export default AuthProvider;
