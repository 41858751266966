import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    value: null
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    },
    clear: state => {
      state.value = null
    }
  }
})
export const { set, clear } = authSlice.actions;

export const selectAuth = state => state.auth.value;

export default authSlice.reducer