import React from 'react';
import { Button, Table } from "antd";
import moment from 'moment';
export default function MatchResult({result}) {
    console.log("Result",result);

    const onRowClick = (id) => {
        window.open(`https://higherre.com/resumes/${id}`, "_blank");
    };

    const columns = [
        {
            title: '순위',
            dataIndex: 'matchRank',
            key: 'matchRank',
            width: 70
        },
        {
            title:'이력서 id',
            dataIndex: 'resumeId',
            key: 'resumeId',
            width: 70
        },
        {
            title:'유저 이름',
            dataIndex: 'userName',
            key: 'userName',
            width: 100
        },
        {
            title:'유저 기입 주소',
            dataIndex: 'address',
            key: 'address',
            width: 200
        },
        {
            title:'유저 생년월일',
            dataIndex: 'userBirthDate',
            key: 'userBirthDate',
            render: (createdDateTime) =>
                moment(createdDateTime).format("YYYY.MM.DD"),
            width: 120
        },
        {
            title:'유저 성별',
            dataIndex: 'userGender',
            key: 'userGender',
            width: 70
        },
        {
            title:'매칭 점수',
            dataIndex:'matchScore',
            key: 'matchScore',
            width: 70
        },
        {
            title:'점수 로그',
            dataIndex: 'log',
            key: 'log',
            render: (log) => 
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    {JSON.stringify(log)}
                </div>
                ,
            width: 400
        },
        {
            title: "이력서 보기",
            dataIndex: "resumeId",
            key: "resume-link",
            render: (resumeId) => <Button type="link" onClick={() => onRowClick(resumeId)}>이력서 보러가기</Button>
        }
    ]

    return (
    <div class=''>
        <div class='text-xl'>매칭결과</div>
        <div class='flex flex-col gap-[10px]'>{result? 
            // result.matchResults?.map((item, index) => (
            //     <MatchResultItem item={item} key={index} />
            // ))
            <Table
            rootClassName="w-[70vw]"
            columns={columns}
            dataSource={result?.matchResults}
            pagination={false}
            size="large"
            />
            :    
            '아직 매칭결과가 없습니다.'}
        </div>
    </div>
    );
}

function MatchResultItem({item:{matchRank, resumeId, address, userBirthDate, userGender, userName, matchScore, log}}){
    return (
        <a href={`https://higherre.com/resumes/${resumeId}`} target="_blank">
        <div class='flex gap-[10px] py-[5px] border-solid border-2 '>
            <div>
                순위 : {matchRank}
            </div>
            <div>
                이력서 번호 : {resumeId}
            </div>
            <div>
                유저이름 : {userName}
            </div>
            <div>
                유저 기입 주소 : {address}
            </div>
            <div>
                유저생년월일 : {userBirthDate}
            </div>
            <div>
                유저성별 : {userGender}
            </div>
            <div>
                매칭점수 : {matchScore}
            </div>
            <div>
                log : {JSON.stringify(log)}
            </div>
        </div>
        </a>
    );
}   