import axios from "axios";
import { toast } from "react-toastify";

export const KAKAO_LOGIN_URL = "/oauth2/authorization/kakao";

const httpClient = axios.create({
  baseURL:
    process.env.REACT_APP_PROFILE === "PROD"
      ? process.env.REACT_APP_API_URL
      : null,
  headers: {
    "higher-client": "admin",
  },
  timeout: 3000,
  withCredentials: true,
});

httpClient.interceptors.response.use(
  function (response) {
    console.log(response);

    return response;
  },
  function (error) {
    if (error.response && error.response.status !== 401) {
      toast("서비스 네트워크 에러입니다. 관리자에게 문의하세요.");

      return Promise.reject(error);
    }
  }
);

export const postKakaoLogin = () => httpClient.post(KAKAO_LOGIN_URL);

export const logout = () => httpClient.post("/v1/auth/logout");

export const getAllUsers = (page, size, name) =>
  httpClient.get("/v1/admin/users", {
    params: {
      page,
      size,
      name
    },
  });

export const getAllResumes = (page, size) =>
  httpClient.get("/v1/admin/resumes", {
    params: {
      page,
      size,
    },
  });

export const getAdminUserInfo = () => httpClient.get("/v1/auth/session/admin");

export const getMatch = (matchQuery) =>
  httpClient.get("/v1/admin/employer/jobposts/temp-match", {
    params: matchQuery,
    timeout: 30000,
  });

export const deleteResumeWithApplication = (resumeId) =>
    httpClient.delete(`/v1/resumes/${resumeId}`);
