import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Redirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');

  }, []);

  return <>로그인 중...</>;
}
