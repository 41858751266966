export default function MatchForm ({onSubmit, onChange, matchQuery, loading, errorMessage}) {
    return (
        <form onSubmit={onSubmit}>
            <div class='flex flex-col gap-[10px] mt-[20px]'>
                <div>
                    약국이름
                    <input 
                    type='text' name='name' placeholder='약국이름 (생략가능)'
                    value={matchQuery.name}
                    onChange={onChange}
                    />
                </div>
                <div>
                    주소
                    <input
                    type='text' name='address' placeholder='정확한 주소 입력'
                    value={matchQuery.address}
                    onChange={onChange}
                    />
                </div>
                <div>
                    거리 선호 순위 (1,2,3)
                    <input
                    type='text' name='preferNearest' placeholder='1,2,3 중에 하나만 입력'
                    value={matchQuery.preferNearest}
                    onChange={onChange}
                    />
                </div>
                <div>
                    경력 선호 순위 (1,2,3)
                    <input
                    type='text' name='preferExperience' placeholder='1,2,3 중에 하나만 입력'
                    value={matchQuery.preferExperience}
                    onChange={onChange}
                    />
                </div>
                <div>
                    자격증 선호 순위 (1,2,3)
                    <input
                    type='text' name='preferComputer' placeholder='1,2,3 중에 하나만 입력'
                    value={matchQuery.preferComputer}
                    onChange={onChange}
                    />
                </div>
            
                <button 
                    type='submit'
                    class='border-solid border-2'
                >
                        매칭 결과 조회
                </button>
            </div>
            <div class='text-blue-800'>{loading && "로딩중입니다."}</div>
            <div class='text-red-800'>{errorMessage}</div>
      </form>
    );
}